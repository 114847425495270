import { Direction, elementsRefs, direction, debounce } from "./utils"
// import debounce from "debounce"
import throttle from "lodash.throttle"

//TODO: Maybe rework w/ scroll-swipe?


const tag = "[SectionScroll]";
const tpl = "%tag% failed to resolve `target`";

const debounced = (fn, leading = false) => debounce(fn, 500, leading);

/**
 * Handle a "scroll to next section" semantic on scroll
 */
export default class SectionScroll{
	/**
	 * @param {any} sections
	 * @param {number} delta - Amount of pixels after which a transition should be done
	 */
	constructor(sections, delta = 40){
		this.autoBind();
		this.sections = elementsRefs(sections);
		this.delta = delta;
		this.direction = Direction.DOWN;
		this.scrolling = false;
		this.offset = this.captureOffset();
		this.index = this.computeIndex();

		this.addListeners();
		this.onOrientation();
	}

	addListeners(){
		// const onScroll = debounced(this.handleScroll);
		const onScroll = debounced(this.handleScroll);
		const onRes = debounced(this.onOrientation);

		window.addEventListener("scroll", onScroll);
		window.addEventListener("orientationchange", onRes);
		window.addEventListener("resize", onRes);
		window.addEventListener("keydown", e => {
			switch(e.key){
				case "Home":
					e.preventDefault();
					return this.scrollToSection(0);

				case "End":
					e.preventDefault();
					return this.scrollToSection(this.sections.length - 1);

				case "PageUp":
					e.preventDefault();
					return this.scrollUp();

				case "PageDown":
					e.preventDefault();
					return this.scrollDown();

				default:
					break;
			}
		});
	}

	/**
	 * Bind current object to its event handlers
	 * @private
	 */
	autoBind(){
		this.handleScroll = this.handleScroll.bind(this);
		this.snapToClosest = this.snapToClosest.bind(this);
		this.onOrientation = this.onOrientation.bind(this);
	}

	/**
	 * Handle the current scroll event
	 * @param {Event|null|undefined} e - The scroll event that occured
	 */
	handleScroll(e){
		if(e && !this.scrolling){
			e.preventDefault();
		}else if(this.scrolling){
			return;
		}

		// this.snapToClosest();
		this.onScroll();
	}


	/**
	 * Get the current scroll offset
	 * @private
	 * @returns {number}
	 */
	captureOffset(){
		return window.scrollY;
	}

	/**
	 * Respond to orientation change events
	 * @private
	 * @param {Event|null|undefined} e - The event
	 */
	onOrientation(){
		this.offset = this.captureOffset();
		this.snapToClosest();
	}

	/**
	 * Respond to scroll change events
	 * @private
	 * @param {Event|null|undefined} e - The event
	 */
	onScroll(){
		const newOffset = this.captureOffset();
		const { offset } = this;
		const diff = Math.abs(offset - newOffset);
		if(diff < this.delta)
			return; // do not handle change before scrolling of, at least, a given delta

		/* if(this.scrolling)
			return;

		this.scrolling = true; */

		if(diff > window.innerHeight)
			return this.snapToClosest();

		this.direction = this.__direction();
		console.log(`[START] direction: ${Direction[this.direction]}, index: ${this.index}, offset: ${this.offset}`);

		switch(this.direction){
			case Direction.UP:
				return this.scrollUp();

			case Direction.DOWN:
				return this.scrollDown();

			default:
				break;
		}

		this.scrolling = false;
	}

	/**
	 * Scroll to the previous section
	 */
	scrollUp(){
		if(this.index <= 0){
			this.index = 0;
			return;
		}

		this.__closestIndex();
		this.scrollToSection(this.index - 1);
	}

	/**
	 * Scroll to the next section
	 */
	scrollDown(){
		const maxIndex = this.sections.length - 1;
		if(this.index >= maxIndex){
			this.index = maxIndex;
			return;
		}

		this.scrollToSection(this.index + 1);
	}

	/**
	 * Scroll to the given section
	 * @param {number} index - The index of the section to scroll to
	 */
	scrollToSection(index){
		const section = this.sections[index];
		this.__doScroll(section, index);
	}

	/**
	 * Compute the index of the closest section
	 * @private
	 * @returns {number}
	 */
	computeIndex(){
		const closest = this.__closest();
		return this.sections.indexOf(closest);
	}

	/**
	 * Actual scrolling logic
	 * @private
	 * @param {Element} el - The element to scroll to (its top)
	 */
	__doScroll(el, newIndex = undefined){
		if(typeof el.scrollIntoView === "function")
			el.scrollIntoView({
				behavior: "smooth",
			});
		else
			window.scrollTo(0, el.offsetTop);

		setTimeout(() => {
			if(typeof newIndex === "number")
				this.index = newIndex;

			// this.index = this.computeIndex();
			this.scrolling = false;
			this.offset = this.captureOffset();
			console.log(`[STOP] index: ${this.index}, offset: ${this.offset}`);
		}, 500);
	}

	/**
	 * Get the current direction
	 * @private
	 * @returns {Direction}
	 */
	__direction(){
		const newOffset = this.captureOffset();
		const { offset } = this;
		return direction(newOffset - offset);
	}

	/**
	 * Compute the index of the closest section
	 * @private
	 * @returns {number}
	 */
	__closestIndex(){
		this.offset = this.captureOffset();
		const diffs = this.sections.map(el => this.offset - el.offsetTop);
		const minDiff = diffs.reduce((min, diff) => {
			if(isNaN(min))
				return diff;

			if(diff <= 0)
				return min;

			return Math.min(diff, min);
		}, NaN);
		return diffs.indexOf(minDiff);
	}

	/**
	 * Get the closest section
	 * @private
	 * @returns {Element}
	 */
	__closest(){
		const index = this.__closestIndex();
		return this.sections[index];
	}

	/**
	 * "Snap" scroll to the closest section
	 */
	snapToClosest(){
		const index = this.__closestIndex();
		this.__doScroll(this.sections[index], index);
	}
}