import "@scss/main.scss"
import "picturefill"
import "lazysizes"

import SmoothScroll from "smooth-scroll"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import AnimateNumber from "./AnimateNumber"
import ScrollSnap from "./ScrollSnap"
import SectionScroll from "./SectionScroll"
import { Browser } from "./BrowserSpecific"
import { swallowErrors } from "./utils"

import fullpage from "fullpage.js"

const B = Browser(window).bootstrapClasses();
// for webp detection, see https://github.com/Modernizr/Modernizr/blob/3b0eb2be8cc847c0f331a5327bf8cd2f19632ffa/feature-detects/img/webp.js#L43

document.addEventListener("DOMContentLoaded", function () {
	swallowErrors(() => {
		const animatedNumber = new AnimateNumber(".o-top__version", {
			delay: 15, //ms
			endFactory(el) {
				return parseFloat(el.dataset.version);
			},
		});

		animatedNumber.animate();
	});

	swallowErrors(() => {
		const doIt = items => items.forEach((e, i) => e.zIndex = 2+i);

		doIt(document.querySelectorAll(".m-features__feature"));
		doIt(document.querySelectorAll(".stack__container section"));
	});

	// Smooth anchor scroll
	new SmoothScroll("a[href*='#']");

	const shouldUseScrollEffect = B.ie() || (B.safari() && B.desktop());

	if(shouldUseScrollEffect){
		// Scroll on features
		gsap.registerPlugin(ScrollTrigger);
		let counter = 0;
		document.querySelectorAll(".m-features__feature span").forEach(feature => {
			feature.style.transformOrigin = counter % 2 == 0 ? "top left" : "top right";
			gsap.from(feature, {
				scrollTrigger: {
					trigger: feature,
					start: "top bottom", // when the top of the trigger hits the top of the viewport
					end: "top center", // end after scrolling 500px beyond the start (was "middle top")
					scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
				},
				skewY: counter % 2 == 0 ? 8 : -8,
				opacity: 0,
			});
			// console.log(counter % 2)
			counter++;
		});
	}

})


function getParameter(theParameter) {
	const params = window.location.search.substr(1).split("&")
	for (const param of params) {
		const p = param.split("=")
		if (p[0] == theParameter) {
			return decodeURIComponent(p[1])
		}
	}
	return false
}

// UTM to Cookies
const urlSrc = getParameter("utm_source")
const urlMdm = getParameter("utm_medium")
const urlCpn = getParameter("utm_campaign")
if (urlSrc)
	document.cookie = `utm_source=${urlSrc}; expires=3600; path = /`;
if (urlMdm)
	document.cookie = `utm_medium=${urlMdm}; expires=3600; path = /`;
if (urlCpn)
	document.cookie = `utm_campaign=${urlCpn}; expires=3600; path = /`;

// SWPL LOG
console.log(
	"%c S W P L ",
	`background: #1467ff;
	color: white;
	font-size: 17px;
	font-weight: bold;
	line-height: 36px; text-align: center`,
	" www.swpl.fr"
)