const numberFormat = nb => {
	const precision = nb < 1 ? 1 : 2;
	return nb.toPrecision(precision);
};

const extractText = el => parseFloat(el.textContent);

/**
 * @typedef {object} AnimateNumberSettings
 * @property {number} delay - The delay (in ms) between each step
 * @property {number} step - The step to increment by in each iteration
 * @property {number} start - The number to start animating from
 * @property {(el: Element) => number} endFactory - The number to animate towards
 * @property {(nb: number) => number|string} formatter - The formatter to use to display the number
 */

export default class AnimateNumber{
	/**
	 * Animate a number inside the given element
	 * @param {Element|string} element - The element to target
	 * @param {AnimateNumberSettings} settings - The settings of the animation
	 */
	constructor(element, {
		delay = 50,
		step = 0.1,
		start = 0,
		endFactory = extractText,
		formatter = numberFormat,
	} = {}){
		this.element = element;
		if(typeof element === "string")
			this.element = document.querySelector(element);

		if(!(this.element instanceof Element))
			throw new TypeError("[AnimateNumber] constructor could not resolve `element`");

		this.start = start;
		this.end = endFactory(this.element) ;
		this.delay = delay;
		this.step = step;
		this.formatter = formatter;
		this.interval = null; // will store the interval handler (to clear it)
	}

	/**
	 * Set the text to the given/current value
	 * @private
	 * @param {number} [nb = this.value] - The value to set the text to
	 */
	setText(nb = this.value){
		this.element.textContent = this.formatter(nb);
	}

	/**
	 * Start (or restart) the animation
	 */
	animate(){
		if(this.interval !== null)
			clearInterval(this.interval);

		this.value = this.start;
		this.setText(this.value);
		this.interval = setInterval(this.doAnimate.bind(this), this.delay);
	}

	/**
	 * Process an animation step
	 * @private
	 */
	doAnimate(){
		if(this.value > this.end){
			clearInterval(this.interval);
			return;
		}

		this.setText(this.value);
		this.value += this.step;
	}
}