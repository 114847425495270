import { Direction, elementRef } from "./utils"
import anime from "animejs"

const tag = "[ScrollSnap]";
const tpl = "%tag% constructor could not resolve %el%";
const primary = "#ab242b";
const white = "white";

/**
 * @param {Element} el
 */
const siblings = el => {
	const parent = el.parentElement;
	const children = new Set(parent.children);
	children.delete(el);
	return Array.from(children.values());
};

export default class ScrollSnap{
	/**
	 * Create an effect that snaps the scroll to a given element until it's completely into view
	 * @param {import("./utils").ElementReference} target - The element to snap the scroll into
	 * @param {import("./utils").ElementReference|undefined} [parent = document.body] - The element which scroll will be lost while the target has snapped into
	 */
	constructor(target, sectionSelector){
		this.target = elementRef(target, tag, tpl);
		this.sectionSelector = sectionSelector;

		/**
		 * @type {IntersectionObserverInit}
		 */

		this.direction = Direction.DOWN;

		// this.setupSlides();
		// this.setupFullpage(); //uncomment for pagewide sections
		// this.setupLoop();


		// this.anime();
		this.items().forEach((e, i) => e.zIndex = 2+i);
	}

	anime(){
		const ml4 = {};
		ml4.opacityIn = [0, 1];
		ml4.scaleIn = [0.2, 1];
		ml4.scaleOut = 3;
		ml4.durationIn = 800;
		ml4.durationOut = 600;
		ml4.delay = 500;

		this.animation = anime.timeline({loop: true});

		this.items().forEach((item, i) => {
			this.animation = this.animation.add({
				targets: item,
				opacity: ml4.opacityIn,
				scale: ml4.scaleIn,
				duration: ml4.durationIn,
			}).add({
				targets: item,
				opacity: 0,
				scale: ml4.scaleOut,
				duration: ml4.durationOut,
				easing: "easeInExpo",
				delay: ml4.delay,
			});
		});

		/* this.animation = this.animation.add({
			targets: this.target,
			opacity: 0,
			duration: 500,
			delay: 500,
		}).add({
			targets: this.target,
			opacity: 1,
			duration: 500,
		}); */
	}

	setupLoop(){
		let i = 0;
		const updateIndex = () => {
			i += 1;
			i %= this.items().length;
		};

		const run = () => {
			this.target
			.querySelectorAll(`${this.sectionSelector}.active`)
			.forEach(el => el.classList.remove("active"));

			this.items()[i].classList.add("active");
			updateIndex();
			setTimeout(run, 2500);
		};

		const threshold = 0.5;
		this.observer = new IntersectionObserver((entries, observer) => {
			entries.forEach(entry => {
				if(entry.intersectionRatio > threshold){
					console.log(entry);
					run();
					observer.unobserve(entry.target);
				}
			});
		}, {
			threshold
		});
		this.observer.observe(this.target);
	}

	/* setupSlides(){
		this.swiper = new Swiper(this.target, {
			direction: "vertical",
			pagination: {
				type: "progressbar",
				el: ".swiper-pagination",
			},
			autoplay: true,
		});
	} */

	setupFullpage(){
		this.fullpage = new fullpage(this.target, {
			sectionSelector: this.sectionSelector,
			// autoScrolling: false,
			controlArrows: false,
			fitToSection: false,
			// scrollOverflow: true,
		});
	}

	/**
	 * @returns {HTMLCollection}
	 */
	items(){
		return this.target.querySelectorAll(this.sectionSelector);
	}
}