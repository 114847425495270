//cf. https://gitlab.com/swpl/clients/dynamips-dynaexpo2019/-/blob/master/src/js/helpers/browser.js

/**
 * @class Detector
 * @classdesc A class used for JS browser/platform detection
 */
class Detector{
    constructor(global = window){
        this.global = global;
        this.navigator = this.global.navigator;
        this.ua = this.navigator.userAgent.toLowerCase();
        this.vendor = this.navigator.vendor.toLowerCase() || "";
        this.platform = this.navigator.platform.toLowerCase();

        this.bootstrapped = false;
    }

    // CSS Setup
    bootstrapClasses(){
        if(this.bootstrapped)
            return this;

        const html = this.global.document.documentElement;

        [
            //Browsers
            "ie",
            "edge",
            "chrome",
            "opera",
            "firefox",
            "safari",
            "vivaldi",

            //Specific Browsers
            "chromeIOS",
            "ieMobile",

            //Platforms
            "windows",
            "mac",
            "linux",
            "android",
            "blackberry",
            "ios",

            //Type
            "desktop",
			"mobile",

			// Devices
			"ipad",
			"ipod",
			"iphone",
        ].forEach(method => {
            if(this[method]())
                html.classList.add(method);
        });

        return this;
    }

    // Pure browsers
    ie(){
        return this.ua.includes("msie") || this.ua.includes("trident");
    }

    edge(){
        return this.ua.includes("edge");
    }

    chrome(){
        return this.ua.includes("chrome")
            && this.vendor.includes("google")
            && !this.opera()
            //&& !this.safari()
            && !this.vivaldi();
    }

    opera(){
        return typeof this.global.opr != "undefined";
    }

    firefox(){
        return this.ua.includes("firefox");
    }

    safari(){
        return this.ua.includes("safari")
        && !this.vivaldi()
		&& !this.chrome()
		&& !this.edge();
    }

    vivaldi(){
        return this.ua.includes("vivaldi");
    }


    // Specific browsers
    chromeIOS(){
        return this.ua.includes("crios");
    }

    ieMobile(){
        return this.ua.includes("iemobile");
    }

    // Platform
    windows(){
        return this.platform.includes("win");
    }

    mac(){
        return this.platform.includes("mac");
    }

    linux(){
        return this.platform.includes("linux");
    }

    android(){
        return this.ua.includes("android");
    }

    ios(){
        return /i(phone|pad|pod)/i.test(this.ua);
    }

    blackberry(){
        return this.ua.includes("blackberry");
    }

    // Type
    desktop(){
        return !this.mobile();
    }

    mobile(){
        return [
            "chromeIOS",
            "ieMobile",

            "android",
            "ios",
            "blackberry",
        ].some(k => this[k]())
	}

	// Devices
	ipad(){
		return this.ios() && this.ua.includes("ipad");
	}

	iphone(){
		return this.ios() && this.ua.includes("iphone");
	}

	ipod(){
		return this.ios() && this.ua.includes("ipod");
	}
}

const Browser = (global = window) => new Detector(global);

export {
    Browser
}
