/**
 * @typedef {string|Element} ElementReference
 * @typedef {string|NodeList|ElementReference[]} ElementsReference
 */


/**
 * Get a reference to a DOM Element
 * @param {ElementReference} el - The element to get a reference to
 * @param {string} errorTag - The error tag to indicate on failure
 * @param {string} errorTpl - Template error message (%tag%, %el%)
 * @returns {Element}
 */
export const elementRef = (el, errorTag = "", errorTpl = "%tag% could not resolve DOM Element: %el%") => {
	let ret = null;

	if(el instanceof Element)
		return el;
	else if(typeof el === "string")
		ret = document.querySelector(el);

	if(!(ret instanceof Element))
		throw new TypeError(errorTpl.replace("%tag%", errorTag).replace("%el%", el));

	return ret;
};

/**
 * Get references to DOM Elements
 * @param {ElementsReference} el - The elements to get a reference to
 * @param {string} errorTag - The error tag to indicate on failure
 * @param {string} errorTpl - Template error message (%tag%, %el%)
 * @returns {Element[]}
 */
export const elementsRefs = (el, errorTag = undefined, errorTpl = undefined) => {
	if(typeof el === "string")
		return Array.from(document.querySelectorAll(el));
	else if(el instanceof Array)
		return el.map(e => elementRef(e, errorTag, errorTpl));
	else if(el instanceof NodeList)
		return Array.from(el);
	else
		return [];
}

const noop = () => {};

/**
 * Execute code and swallow its errors
 * @param {() => any} fn - The code to swallow errors for
 * @param {(e: Error|string) => any} [logger = noop] - The error logging handler
 */
export const swallowErrors = (fn, logger = noop) => {
	try{
		fn();
	}catch(e){
		logger(e);
	}
};

/**
 * Enum for directions
 * @readonly
 * @enum {number}
 */
export const Direction = {
	DOWN: 0,
	UP: 1,

	0: "DOWN",
	1: "UP",
}

/**
 * Compute a direction from a number (referring to a directed amount of pixel)
 * @param {number} nb
 * @returns {Direction}
 */
export const direction = nb => nb > 0 ? Direction.DOWN : Direction.UP;

export const compareAsc = (lhs, rhs) => lhs - rhs;

export const compareDesc = (lhs, rhs) => compareAsc(rhs, lhs);

// cf. https://davidwalsh.name/javascript-debounce-function
export function debounce(func, wait, immediate = false) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};